import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProjectMarketingStaffs(
    payload = {
        queries: {},
        page: 1,
        perPage: 30
    }
) {
    const include = "rolePermission";
    try {
        const response = await axios.get(
            `${adminAPI}/project-marketing-staffs`,
            {
                params: {
                    ...payload.queries,
                    page: payload.page,
                    limit: payload.perPage,
                    include: include
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, "[Admin CP] Get Project M.Staff");
        throw error;
    }
}
async function getProjectMarketingStaff(id) {
    const include = "rolePermission";
    try {
        const response = await axios.get(
            `${adminAPI}/project-marketing-staffs/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Project M.Staff [ID: ${id}]`);
        throw error;
    }
}

async function createProjectMarketingStaff(payload) {
    try {
        const response = await axios.post(
            `${adminAPI}/project-marketing-staffs`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create M.Staff`);
        throw error;
    }
}

async function updateProjectMarketingStaff(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/project-marketing-staffs/${id}`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit M.Staff [ID: ${id}]`);
        throw error;
    }
}

async function updatePassword(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/project-marketing-staffs/${id}/update-password`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update M.Staff Password [ID: ${id}]`);
        throw error;
    }
}

async function editActive(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/project-marketing-staffs/${id}/update-active`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update M.Staff Active [ID: ${id}]`);
        throw error;
    }
}

async function deleteProjectMarketingStaff(id) {
    try {
        const response = await axios.delete(
            `${adminAPI}/project-marketing-staffs/${id}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `Delete M.Staff [ID: ${id}]`);
        throw error;
    }
}

export default {
    getProjectMarketingStaffs,
    getProjectMarketingStaff,
    createProjectMarketingStaff,
    updateProjectMarketingStaff,
    editActive,
    updatePassword,
    deleteProjectMarketingStaff
};
