import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllDevelopers(payload = { limit: 50 }) {
    try {
        let include = "";

        let response = await axios.get(`${adminAPI}/developers`, {
            params: {
                ...payload,
                include: include
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function getDeveloper(id) {
    let include = "";

    try {
        let response = await axios.get(
            `${adminAPI}/developers/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function getDeveloperRoles(id) {
    try {
        let response = await axios.get(`${adminAPI}/developers/${id}/roles`);

        return response.data;
    } catch (error) {
        throw error;
    }
}

async function createDeveloper(payload) {
    try {
        const response = await axios.post(`${adminAPI}/developers`, payload);

        return response.data;
    } catch (error) {
        throw error;
    }
}

async function updateDeveloper({ id, payload }) {
    try {
        const request = await axios.put(
            `${adminAPI}/developers/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        throw error;
    }
}

async function deleteDeveloper(id) {
    try {
        const response = await axios.delete(`${adminAPI}/developers/${id}`);

        return response.data;
    } catch (error) {
        throw error;
    }
}

export default {
    getAllDevelopers,
    getDeveloper,
    getDeveloperRoles,
    createDeveloper,
    updateDeveloper,
    deleteDeveloper
};
