import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllLawyers(payload = { limit: 20 }) {
    try {
        let include = "";

        let response = await axios.get(`${adminAPI}/lawyers`, {
            params: {
                ...payload,
                include: include
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Lawyers`);
        throw error;
    }
}

async function getLawyer(id) {
    let include = "";

    try {
        let response = await axios.get(
            `${adminAPI}/lawyers/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Lawyer [ID: ${id}]`);
        throw error;
    }
}

async function createLawyer(payload) {
    try {
        const response = await axios.post(`${adminAPI}/lawyers`, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create Lawyer`);
        throw error;
    }
}

async function updateLawyer(id, payload) {
    try {
        const request = await axios.put(`${adminAPI}/lawyers/${id}`, payload);

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit Lawyer [ID: ${id}]`);
        throw error;
    }
}

async function updatePassword(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/lawyers/${id}/update-password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Lawyer Pwd [ID: ${id}]`);
        throw error;
    }
}

async function editActive(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/lawyers/${id}/update-active`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Lawyer Active [ID: ${id}]`);
        throw error;
    }
}

async function deleteLawyer(id) {
    try {
        const response = await axios.delete(`${adminAPI}/lawyers/${id}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete Lawyer [ID: ${id}]`);
        throw error;
    }
}

export default {
    getAllLawyers,
    getLawyer,
    createLawyer,
    updateLawyer,
    updatePassword,
    editActive,
    deleteLawyer
};
