import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

// ======================= Main CRUD  ========================
async function getAgencyUsers(payload) {
    let include = "agency,agencyBranch,rolePermission";

    try {
        let response = await axios.get(`${adminAPI}/agency-users`, {
            params: {
                ...payload,
                include: include
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, "[AdminCP] Get Agency Users");
        throw error;
    }
}
async function getAgencyUser(id) {
    let include = "agency,agencyBranch,rolePermission";

    try {
        let response = await axios.get(
            `${adminAPI}/agency-users/${id}?include=${include}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[AdminCP] Get Agency User (#${id})`);
        throw error;
    }
}
async function createAgencyUser(payload) {
    try {
        const response = await axios.post(`${adminAPI}/agency-users`, payload);

        return response.data;
    } catch (error) {
        reportError(error, "[AdminCP] Create Agency User");
        throw error;
    }
}
async function updateAgencyUser({ id, payload }) {
    try {
        const response = await axios.put(
            `${adminAPI}/agency-users/${id}`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[AdminCP] Edit Agency User #${id}`);
        throw error;
    }
}
async function deleteAgencyUser(id) {
    try {
        const response = await axios.delete(`${adminAPI}/agency-users/${id}`);

        return response.data;
    } catch (error) {
        reportError(error, `[AdminCP] Delete Agency User #${id}`);
        return false;
    }
}
async function setAgencyUserStatus({ id, isActive }) {
    try {
        const response = await axios.put(
            `${adminAPI}/agency-users/${id}/update-active`,
            { isActive: isActive }
        );

        return response;
    } catch (error) {
        reportError(error, `[AdminCP] Set Agency User Status #${id}`);
        throw error;
    }
}

async function updatePassword(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/agency-users/${id}/update-password`,
            payload
        );

        return response;
    } catch (error) {
        reportError(error, `[AdminCP] Change Agency User Password  #${id}`);
        throw error;
    }
}

export default {
    getAgencyUsers,
    getAgencyUser,
    createAgencyUser,
    updateAgencyUser,
    deleteAgencyUser,
    setAgencyUserStatus,
    updatePassword
};
