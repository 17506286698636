import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllDesigners(payload = { limit: 20 }) {
    try {
        let include = "";

        let response = await axios.get(`${adminAPI}/interior-designers`, {
            params: {
                ...payload,
                include: include
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Designers`);
        throw error;
    }
}

async function getDesigner(id) {
    let include = "";

    try {
        let response = await axios.get(
            `${adminAPI}/interior-designers/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Designer [ID: ${id}]`);
        throw error;
    }
}

async function createDesigner(payload) {
    try {
        const response = await axios.post(
            `${adminAPI}/interior-designers`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create Designer`);
        throw error;
    }
}

async function updateDesigner(id, payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/interior-designers/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit Designer [ID: ${id}]`);
        throw error;
    }
}

async function updatePassword(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/interior-designers/${id}/update-password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Designer Pwd [ID: ${id}]`);
        throw error;
    }
}

async function editActive(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/interior-designers/${id}/update-active`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Designer Active [ID: ${id}]`);
        throw error;
    }
}

export default {
    getAllDesigners,
    getDesigner,
    createDesigner,
    updateDesigner,
    updatePassword,
    editActive
};
