import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getLADocFormCategories() {
    try {
        let include = "";

        let response = await axios.get(
            `${adminAPI}/loan-application-document-categories`,
            {
                params: {
                    include: include,
                    limit: 50
                }
            }
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get LA Doc Categories`);
        throw error;
    }
}

async function getLADocFormCategory(id) {
    let include = "loanApplicationDocuments";

    try {
        let response = await axios.get(
            `${adminAPI}/loan-application-document-categories/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get LA Doc Category [ID: ${id}]`);
        throw error;
    }
}

async function createLADocFormCategory(payload) {
    try {
        const response = await axios.post(
            `${adminAPI}/loan-application-document-categories`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create LA Doc Category`);
        throw error;
    }
}

async function updateLADocFormCategory(id, payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/loan-application-document-categories/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit LA Doc Category [ID: ${id}]`);
        throw error;
    }
}

async function deleteLADocFormCategory(id) {
    try {
        const response = await axios.delete(
            `${adminAPI}/loan-application-document-categories/${id}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete LA Doc Category [ID: ${id}]`);
        throw error;
    }
}

async function deleteLADocFormField(id) {
    try {
        const response = await axios.delete(
            `${adminAPI}/loan-application-documents/${id}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete LA Doc Field [ID: ${id}]`);
        throw error;
    }
}

async function getLADocAdditionalField() {
    try {
        let include = "";

        let response = await axios.get(
            `${adminAPI}/loan-application-additional-documents`,
            {
                params: {
                    include: include,
                    limit: 50
                }
            }
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get LA Doc Additional`);
        throw error;
    }
}

async function updateLADocAdditionalField(payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/loan-application-additional-documents`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update LA Doc Field`);
        throw error;
    }
}

async function deleteLADocAdditionalField(id) {
    try {
        const response = await axios.delete(
            `${adminAPI}/loan-application-additional-documents/${id}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete LA Doc Additional [ID: ${id}]`);
        throw error;
    }
}

export default {
    getLADocFormCategories,
    getLADocFormCategory,
    createLADocFormCategory,
    updateLADocFormCategory,
    deleteLADocFormCategory,
    deleteLADocFormField,

    getLADocAdditionalField,
    updateLADocAdditionalField,
    deleteLADocAdditionalField
};
