import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllBankers(payload = { limit: 20 }) {
    try {
        let include = "";

        let response = await axios.get(`${adminAPI}/bankers`, {
            params: {
                ...payload,
                include: include
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Bankers`);
        throw error;
    }
}

async function getBanker(id) {
    let include = "";

    try {
        let response = await axios.get(
            `${adminAPI}/bankers/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Banker [ID: ${id}]`);
        throw error;
    }
}

async function createBanker(payload) {
    try {
        const response = await axios.post(`${adminAPI}/bankers`, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create Banker`);
        throw error;
    }
}

async function updateBanker(id, payload) {
    try {
        const request = await axios.put(`${adminAPI}/bankers/${id}`, payload);

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit Banker [ID: ${id}]`);
        throw error;
    }
}

async function updatePassword(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/bankers/${id}/update-password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Banker Pwd [ID: ${id}]`);
        throw error;
    }
}

async function editActive(id, payload) {
    try {
        const response = await axios.put(
            `${adminAPI}/bankers/${id}/update-active`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Update Banker Active [ID: ${id}]`);
        throw error;
    }
}

async function deleteBanker(id) {
    try {
        const response = await axios.delete(`${adminAPI}/bankers/${id}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete Banker [ID: ${id}]`);
        throw error;
    }
}

export default {
    getAllBankers,
    getBanker,
    createBanker,
    updateBanker,
    updatePassword,
    editActive,
    deleteBanker
};
