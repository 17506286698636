import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getBanners() {
    try {
        let response = await axios.get(`${adminAPI}/home-banners`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Banners`);
        throw error;
    }
}

async function getBanner(id) {
    try {
        let response = await axios.get(`${adminAPI}/home-banners/${id}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Banner #${id}`);
        throw error;
    }
}

async function createBanner(payload) {
    try {
        const response = await axios.post(`${adminAPI}/home-banners`, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create Banner`);
        throw error;
    }
}

async function updateBanner(id, payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/home-banners/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit Banner #${id}`);
        throw error;
    }
}

async function sortBanner(payload) {
    try {
        const request = await axios.put(`${adminAPI}/home-banners/sorting`, {
            data: payload
        });

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Sort Banners`);
        throw error;
    }
}

async function deleteBanner(id) {
    try {
        const response = await axios.delete(`${adminAPI}/home-banners/${id}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete Banner #${id}`);
        throw error;
    }
}

export default {
    getBanners,
    getBanner,
    createBanner,
    updateBanner,
    sortBanner,
    deleteBanner
};
