import axios from "axios";
import { adminAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getPopups() {
    try {
        let response = await axios.get(`${adminAPI}/popup-banners`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Popups`);
        throw error;
    }
}

async function getPopup(id) {
    try {
        let response = await axios.get(`${adminAPI}/popup-banners/${id}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Popup #${id}`);
        throw error;
    }
}

async function createPopup(payload) {
    try {
        const response = await axios.post(`${adminAPI}/popup-banners`, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Create Popup`);
        throw error;
    }
}

async function updatePopup(id, payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/popup-banners/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Edit Popup #${id}`);
        throw error;
    }
}

async function setPopupIsActive(id, payload) {
    try {
        const request = await axios.put(
            `${adminAPI}/popup-banners/${id}/set-active`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Set Popup Active #${id}`);
        throw error;
    }
}

async function sortPopup(payload) {
    try {
        const request = await axios.put(`${adminAPI}/popup-banners/sorting`, {
            data: payload
        });

        return request.data;
    } catch (error) {
        reportError(error, `[Admin CP] Sort Popups`);
        throw error;
    }
}

async function deletePopup(id) {
    try {
        const response = await axios.delete(`${adminAPI}/popup-banners/${id}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Delete Popup #${id}`);
        throw error;
    }
}

export default {
    getPopups,
    getPopup,
    createPopup,
    updatePopup,
    setPopupIsActive,
    sortPopup,
    deletePopup
};
