import axios from "axios";
import { commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProjects(
    queries = {},
    pagination = { page: 1, perPage: 30 }
) {
    try {
        const response = await axios.get(`${commonAPI}/projects`, {
            params: {
                ...queries,
                page: pagination.page,
                limit: pagination.perPage
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Admin CP] Get Projects");
        throw error;
    }
}
async function getProject(id) {
    try {
        const response = await axios.get(`${commonAPI}/projects/${id}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Admin CP] Get Project #${id}`);
        throw error;
    }
}

export default {
    getProjects,
    getProject
};
